<template>
    <main class="main">

        <nav aria-label="breadcrumb" class="breadcrumb-nav mb-0 border-0">
            <div class="container">
                <Breadcrumbs />
                <h4>{{ category.name }}</h4>
            </div>
        </nav>

        <div class="page-content">
            <div class="container">
                <p class="no-results" v-if="category.children.length === 0 && category.product_count == 0">No Categories
                    or product matching your selection.</p>
                <div class="row" v-else>
                    <div class="col-lg-9 skeleton-body skel-shop-products" :class="{ loaded: loaded }">
                        <div class="row">
                            <div class="col-md-12 category-text-seo">
                                <p class="text-dark " v-html="category.description"></p>
                            </div>
                        </div>

                        <pagination-comp :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
                            @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" />

                        <!-- content -->
                        <div class="cat-blocks-container" v-if="!showProducts">
                            <template v-if="type !== 'list'">
                                <div class="row">
                                    <div class="col-6 col-md-4 col-lg-3" v-for="item in paginatedData" :key="item.id">
                                        <b-link :to="`/${item.url_path}`" class="cat-block">
                                            <figure>
                                                <span>
                                                    <img :src="item.image == null ? '' : item.image" :alt="item.name" />
                                                </span>
                                            </figure>

                                            <h3 class="cat-block-title">{{ item.name }}</h3>
                                        </b-link>
                                    </div>
                                </div>
                            </template>

                            <template v-if="type === 'list'">
                                <div class="row">
                                    <div class="col-12" v-for="item in paginatedData" :key="item.id">
                                        <b-link :to="`/${item.url_path}`" class="cat-block-row">
                                            <figure>
                                                <span>
                                                    <img :src="item.image == null ? '' : item.image" :alt="item.name" />
                                                </span>
                                                <h3 class="cat-block-title">{{ item.name }}</h3>
                                            </figure>
                                            <button class="btn btn-dark d-none d-md-block">{{ $t('view_category')
                                                }}</button>
                                        </b-link>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="cat-blocks-container" v-if="showProducts">
                            <template v-if="type !== 'list'">
                                <div class="row">
                                    <div class="col-6 col-md-4 col-lg-3 p-0" v-for="product in paginatedData"
                                        :key="product.url_key">
                                        <product-home :product="product" :nameMaxLength="15"></product-home>
                                    </div>
                                </div>
                            </template>

                            <template v-if="type === 'list'">
                                <div class="row" v-for="product in paginatedData" :key="product.url_key">
                                    <div class="col-12">
                                        <product-home-list :product="product"></product-home-list>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <!-- content -->

                        <pagination-comp :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
                            @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" />

                        <div class="row">
                            <div class="col-md-12 category-text-seo">
                                <div v-html="category.description"></div>
                            </div>
                        </div>
                    </div>

                    <!-- sticky-container -->
                    <aside class="col-lg-3 order-lg-first" >
                        <!-- v-sticky="!isSidebar" sticky-offset="{ top: 69 }" -->
                        <div v-if="aggregation && aggregation.length > 0">
                            <button class="sidebar-fixed-toggler" @click="toggleSidebar" v-if="isSidebar">
                                <i class="icon-cog"></i>
                            </button>

                            <div class="sidebar-filter-overlay" @click="hideSidebar"></div>
                            <shop-sidebar-one :is-sidebar="isSidebar" :aggregation="aggregation"></shop-sidebar-one>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import ShopSidebarOne from '@/esf_weert_mobilesupplies/core/components/partial/shop/sidebar/ShopSidebarOne';
import config from '../../config';
import Breadcrumbs from '@/esf_weert_mobilesupplies/core/components/elements/breadcrumbs.vue'
import ProductHome from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHome';
import ProductHomeList from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHomeList';
import {
    getProductFiltersByCategory,
} from "@storefront/core/data-resolver/products";
import PaginationComp from '@/esf_weert_mobilesupplies/core/components/elements/PaginationComp';
// import { mapGetters } from 'vuex';

export default {
    components: {
        ShopSidebarOne,
        PaginationComp,
        Breadcrumbs,
        ProductHome,
        ProductHomeList,
    },
    data: function () {
        return {
            isSidebar: true,
            loaded: false,
            // aggregation: [{
            //     min_value: '',
            //     max_value: '',
            //     attribute_code: '',
            //     count: '',
            //     label: 'Product',
            //     options: [{
            //         count: '20',
            //         label: 'category',
            //         value: '',
            //         swatch_data: [{
            //             type: '',
            //             value: '',
            //         }]
            //     }]
            // }],
            aggregation: [],
            // pagination
            perPage: 24,
            layout: 'cols',
            totalCount: 0,
            sortBy: 'price_low_high',
            // pagination
        };
    },
    computed: {
        // ...mapGetters({
        //     aggregations: 'product/getAggregations'
        // }),
        category() {
            return this.$store.getters["category/getCurrentCategory"];
        },
        showProducts() {
            if (
                this.category.display_mode === "PRODUCTS" ||
                this.category.display_mode === "PRODUCTS_AND_PAGE"
            ) {
                return true;
            }
            return false;
        },
        // pagination
        type: function () {
            return this.layout;
        },
        currentPage: function () {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        paginatedData() {
            if (!this.category) return [];

            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;

            // Apply sorting based on sortBy
            let sortedCategories = this.showProducts ? [...this.category.products.items] : [...this.category.children];
            if (this.sortBy === 'price_low_high') {
                sortedCategories.sort((a, b) => a.price - b.price);
            } else if (this.sortBy === 'price_high_low') {
                sortedCategories.sort((a, b) => b.price - a.price);
            }
            // Add more sorting logic if needed

            return sortedCategories.slice(start, end);
        },
        // pagination
    },
    watch: {
        $route: function () {
            getProductFiltersByCategory(this.category.id).then((data) => {
                console.log('data', data);
                this.aggregation = data.aggregations;
                // if (this.showProducts) {
                //     this.totalCount = this.category.products.items.length;
                // } else {
                //     this.totalCount = this.category.children.length;
                // }
            });
        },
        category(newcat) {
            if (this.showProducts) {
                this.totalCount = newcat.products.items.length;
            } else {
                this.totalCount = newcat.children.length;
            }
        }
    },
    mounted: function () {
        console.log('aggregations', this.aggregation);
        if (typeof window !== 'undefined') {
            this.resizeHandler();
            window.addEventListener('resize', this.resizeHandler, {
                passive: true
            });
        }
        getProductFiltersByCategory(this.category.id).then((data) => {
            console.log('data', data)
            // if (this.showProducts) {
            //     this.totalCount = this.category.products.items.length;
            // } else {
            //     this.totalCount = this.category.children.length;
            // }
        });
        if (this.showProducts) {
            this.totalCount = this.category.products.items.length;
        } else {
            this.totalCount = this.category.children.length;
        }
    },
    unmounted: function () {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.resizeHandler);
        }
    },
    methods: {
        toggleSidebar: function () {
            if (
                document
                    .querySelector('body')
                    .classList.contains('sidebar-filter-active')
            ) {
                document
                    .querySelector('body')
                    .classList.remove('sidebar-filter-active');
            } else {
                document
                    .querySelector('body')
                    .classList.add('sidebar-filter-active');
            }
        },

        hideSidebar: function () {
            document
                .querySelector('body')
                .classList.remove('sidebar-filter-active');
        },
        resizeHandler: function () {
            if (typeof window !== 'undefined') {
                if (window.innerWidth > 991) this.isSidebar = false;
                else this.isSidebar = true;
            }
        },
        // pagination
        handleLayout: function (layout) {
            this.layout = layout;
        },
        handlePerPage: function (count) {
            this.perPage = count;
        },
        handleSortBy: function (sort) {
            this.sortBy = sort;
        },
        // pagination
    },
    metaInfo() {
        return {
            title: this.category?.name,
            link: [
                {
                    rel: "canonical",
                    href: this.category?.canonical_url
                        ? config.app_url + this.category?.canonical_url
                        : config.app_url +
                        (/\/$/.test(this.$route.fullPath)
                            ? this.$route.fullPath
                            : this.$route.fullPath.replace(/^\//, "") + "/"),
                },
            ],
        };
    },
};
</script>